import React, { useMemo } from "react";
import Linkify from "react-linkify";
import { Trans } from "gatsby-plugin-react-i18next";
import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import { Text, Stack, Box, Link, HStack } from "@chakra-ui/react";

const SearchCard = ({ title, description, type, subtype, prismicId }) => {
  const path = useMemo(() => {
    if (type === "newsreports" && subtype === "allreports") {
      return "/news-reports";
    }
    if (type === "newsreports" && subtype === "event") {
      return "/events";
    }
    if (
      type === "newsreports" &&
      (subtype === "useful_link_item" || subtype === "conference")
    ) {
      return `/conference?id=${prismicId}`;
    }
    if (type === "examinatedcases" || type === "usefulinformation") {
      return "/useful-information";
    }
    if (type === "financialeducation" && subtype === "informationbooklet") {
      return "/all-information-booklets";
    }
    if (type === "financialeducation") {
      return "/financial-education";
    }
    if (
      type === "aboutus" &&
      (subtype === "aboutconstuction" ||
        subtype === "whoweare" ||
        subtype === "trusteesboard" ||
        subtype === "mediator" ||
        subtype === "strategy")
    ) {
      return "/about-us";
    }
    if (type === "aboutus" && subtype === "mediatorItem") {
      return `/mediators-biography?id=${prismicId}`;
    }
    if (type === "aboutus" && subtype === "trusteesBoardItem") {
      return `/trusteesborads-biography?id=${prismicId}`;
    }
    if (type === "whattoexpect" && subtype === "reviews") {
      return `/what-to-expect#reviews`;
    }
    if (
      type === "whattoexpect" &&
      subtype === "whyToApplyMediatorProfessional"
    ) {
      return `/what-to-expect#professional`;
    }
    if (type === "whattoexpect" && subtype === "whyToApplyMediatorProductive") {
      return `/what-to-expect#productive`;
    }
    if (type === "whattoexpect" && subtype === "whyToApplyMediatorImpartial") {
      return `/what-to-expect#impartial`;
    }
    if (type === "whattoexpect" && subtype === "whyToApplyMediatorFast") {
      return `/what-to-expect#fast`;
    }
    if (type === "whattoexpect") {
      return `/what-to-expect`;
    }
    return "/";
  }, [type, subtype]);
  return (
    <Stack
      as={GatsbyLink}
      pb="6"
      borderBottom="1px solid"
      borderColor="border.100"
      direction="row"
      w="full"
      h="136px"
      to={path}
    >
      {/* <Button
        p="0"
        variant="grey"
        w={{ base: "40px", sm: "60px" }}
        minH="40px"
        height={{ base: "40px", sm: "60px" }}
        mr={{ base: "4", sm: "10" }}
      >
        <Image objectFit="cover" src={icon} alt="search-card" />
      </Button> */}

      <Stack w="100%" spacing="3">
        <HStack>
          <Text fontWeight="700" noOfLines={1}>
            {title}
          </Text>
        </HStack>
        <Box>
          <Text
            as="span"
            bg="green.200"
            color="white"
            display="inline-block"
            p={1}
            borderRadius="5px"
            fontSize="sm"
          >
            <Trans>{type}</Trans>
          </Text>
        </Box>
        <Box fontSize="xs" color="grey.100" noOfLines={2}>
          <Text>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <Link
                  key={key}
                  target="_blank"
                  href={decoratedHref}
                  color="green.100"
                  _focus={{ boxShadow: "none" }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {decodeURI(decoratedText)}
                </Link>
              )}
            >
              {description}
            </Linkify>
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
};

export default SearchCard;
