import { useEffect, useState } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import http from "../http";

const LANG = {
  en: "en-us",
  ru: "ru",
  hy: "hy-am",
};

export default function useSearch(search, filters) {
  const { language } = useI18next();
  const [result, setResult] = useState({ items: [], total: null });

  useEffect(() => {
    http
      .get("/", { params: { search, lang: LANG[language], ...filters } })
      .then((response) => setResult({ ...response.data }))
      .catch(console.log);
  }, [search, filters]);

  return result;
}
