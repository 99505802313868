import React, { useMemo } from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import { HStack, Button, Image } from "@chakra-ui/react";
import DoubleAngleIcon from "../../images/double-angle.png";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export default function Pagination({ filters, total, onPageChange }) {
  const pageNeighbors = Math.max(0, Math.min(1, 2));

  const pages = useMemo(() => {
    const totalPages = Math.ceil(total / 20);
    const currentPage = filters.page;

    const totalNumbers = pageNeighbors * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbors;
      const rightBound = currentPage + pageNeighbors;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  }, [total, pageNeighbors, filters.page]);

  const onPrevious = () => onPageChange(filters.page - 1);
  const onNext = () => onPageChange(filters.page + 1);

  const gotoPage = (page) => {
    const currentPage = Math.max(0, Math.min(page, total));
    onPageChange(currentPage);
  };

  const handleMoveLeft = () => {
    gotoPage(filters.page - pageNeighbors * 2 - 1);
  };
  const handleMoveRight = () => {
    gotoPage(filters.page + pageNeighbors * 2 + 1);
  };

  if (pages.length < 2) {
    return <></>;
  }

  return (
    <HStack spacing={3} mt={4} justifyContent="flex-end">
      <Button
        isDisabled={filters.page === 1}
        variant="green"
        onClick={onPrevious}
      >
        <Trans>Previous</Trans>
      </Button>
      {pages?.map((p, index) => {
        if (p === LEFT_PAGE)
          return (
            <Button
              key={`pagination-item-${index}`}
              variant="green"
              onClick={handleMoveLeft}
            >
              <Image
                h="20px"
                src={DoubleAngleIcon}
                alt="Double Angle Icon"
                transform="rotate(180deg)"
              />
            </Button>
          );

        if (p === RIGHT_PAGE)
          return (
            <Button
              key={`pagination-item-${index}`}
              variant="green"
              onClick={handleMoveRight}
            >
              <Image h="20px" src={DoubleAngleIcon} alt="Double Angle Icon" />
            </Button>
          );
        return (
          <Button
            key={`pagination-item-${index}`}
            variant={index + 1 === filters.page ? "darkGreen" : "green"}
            onClick={() => gotoPage(p)}
          >
            {p}
          </Button>
        );
      })}
      <Button
        isDisabled={filters.page === pages.length}
        variant="green"
        onClick={onNext}
      >
        <Trans>Next</Trans>
      </Button>
    </HStack>
  );
}
