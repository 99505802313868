import React, { useState } from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import { Box, Stack, Checkbox, Flex, Button } from "@chakra-ui/react";
import DatePicker from "../../components/datePicker";
import FromTo from "../../assets/from_to.svg";

const TYPES = [
  "aboutus",
  "newsreports",
  "financialeducation",
  "usefulinformation",
  "examinatedcases",
  "whattoexpect",
];

const FilterCheckbox = ({ border, title, data, setTypes }) => {
  const [selectedTypes, setSelectedTypes] = useState([]);

  const onChange = (e) => {
    const { value } = e.target;
    let oldSelectedTypes = [...selectedTypes];
    if (oldSelectedTypes.includes(value)) {
      oldSelectedTypes = oldSelectedTypes.filter((type) => type !== value);
    } else {
      oldSelectedTypes = [value, ...oldSelectedTypes];
    }

    setTypes(oldSelectedTypes);
    setSelectedTypes(oldSelectedTypes);
  };

  const onClearFilters = () => {
    setTypes([]);
    setSelectedTypes([]);
  };

  return (
    <Box
      w="272px"
      border={border ? "1px solid #dddddd" : "none"}
      borderRadius="12px"
      pb="8"
      px="5"
    >
      {title && (
        <Box as="span" display="block" mt="1.25rem" fontWeight="700">
          {data.title}
        </Box>
      )}
      <Box display="inline-block" as="span" fontSize="sm" mt="1rem">
        {data.subTile}
      </Box>
      <Stack spacing="2px" mt="0.5rem">
        {TYPES?.map((checkbox, idx) => (
          <Checkbox
            key={`checkbox-${checkbox}-${idx}`}
            colorScheme="green"
            isChecked={selectedTypes.includes(checkbox)}
            value={checkbox}
            onChange={onChange}
          >
            <Box fontWeight="400" fontSize="13px">
              <Trans>{checkbox}</Trans>
            </Box>
          </Checkbox>
        ))}
      </Stack>
      <Button
        padding="0"
        variant="text"
        color="green.200"
        _hover={{ textDecoration: "underline" }}
        mt="2rem"
        onClick={onClearFilters}
      >
        {data.clearButton}
      </Button>
    </Box>
  );
};

export default FilterCheckbox;
