import React, { useState } from "react";
import { graphql } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";
import {
  Container,
  Box,
  Stack,
  HStack,
  Text,
  Select,
  Flex,
  Drawer,
  Button,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Seo from "../../components/SEO";
import FilterCheckbox from "../../components/search/FilterCheckbox";
import SearchCard from "../../components/search/SearchCard";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import usePrismicSearch from "../../hooks/usePrismicSearch";
import { useTranslation } from "gatsby-plugin-react-i18next";
import useSearch from "../../hooks/useSearch";
import Pagination from "../../components/search/Pagination";

const Search = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { language } = useTranslation();
  const data = usePrismicSearch();
  const [search] = useQueryParam("text", StringParam);
  const [queryPage] = useQueryParam("page", StringParam);
  const [filters, setFilters] = useState({
    page: queryPage ? parseInt(queryPage) : 1,
  });
  const { items, total } = useSearch(search, filters);

  const onFiltersChange = (value, key) =>
    setFilters((oldFilters) => {
      const newFilters = { ...oldFilters, [key]: value };
      if (key === "types") {
        newFilters.page = 1;
      }

      return newFilters;
    });

  const onPageChange = (page) => {
    onFiltersChange(page, "page");
    window.scrollTo({ behavior: "smooth", top: 0 });
  };

  return (
    <Main>
      <Container px="5" fontFamily="Montserrat" pb="12.5rem" maxW="7xl">
        <Seo
          title={data.header}
          description={data.header}
          link={`https://www.fsm.am/${language}/search`}
        />
        <SmallNav>{data.header}</SmallNav>
        <Flex
          mt={{ base: "1.875rem", sm: "10" }}
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box
            position={{ md: "sticky", base: "inherit" }}
            top={{ md: "110px", base: "none" }}
            display={{ base: "none", sm: "block" }}
            mb={{ md: 0, base: 10 }}
          >
            <FilterCheckbox
              border
              title
              data={data}
              setTypes={(types) => onFiltersChange(types, "types")}
            />
          </Box>
          <Box maxW="800px" w="100%">
            <Text mb="3" fontSize="2xl">
              {data.searchResult}
            </Text>
            <Button
              mb="4"
              display={{ base: "inline-flex", sm: "none" }}
              color="green.100"
              onClick={onOpen}
            >
              {data.filter}
            </Button>
            <Drawer
              size="full"
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader boxShadow="0 6px 24px 0 rgba(0,0,0,0.15)">
                  {data.drawerHeader}
                </DrawerHeader>

                <DrawerBody>
                  <FilterCheckbox data={data} />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
            <Flex
              direction={{ base: "column", sm: "row" }}
              mb="3.375rem"
              justifyContent="space-between"
            >
              <Flex mb="0.875rem">
                <Box as="span" fontWeight="700" fontSize="15px">
                  {data.searchFind?.replace("$TOTAL", total || 0)}
                </Box>
              </Flex>
              {/* <Flex>
                <Box
                  mr="1"
                  as="span"
                  color="grey.100"
                  fontWeight="400"
                  fontSize="15px"
                >
                  {data.selectBy}
                </Box>
                <Select
                  fontSize="15px"
                  variant="unstyled"
                  w="auto"
                  fontWeight="700"
                  mr="0.75rem"
                >
                  {data.selectByGroup?.map((option, idx) => (
                    <option key={`option-by-${idx}`}>{option}</option>
                  ))}
                </Select>
                <Box
                  mr="1"
                  as="span"
                  color="grey.100"
                  fontWeight="400"
                  fontSize="15px"
                >
                  {data.showCount}
                </Box>
                <Select
                  variant="unstyled"
                  w="auto"
                  fontWeight="700"
                  fontSize="15px"
                >
                  {data.selectCountGroup?.map((option, idx) => (
                    <option key={`option-count-${idx}`}>{option}</option>
                  ))}
                </Select>
              </Flex> */}
            </Flex>
            <Stack spacing="1.875rem">
              {items?.map((card, idx) => (
                <SearchCard key={`card-${card.title}-${idx}`} {...card} />
              ))}
            </Stack>
            <Pagination
              filters={filters}
              total={total}
              onPageChange={onPageChange}
            />
          </Box>
        </Flex>
      </Container>
    </Main>
  );
};

export default Search;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
