import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicSearch = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicSearch {
     allPrismicSearch(filter: {tags: {in: "search"}}) {
            nodes {
              lang
              data {
                accordiontitle {
                  text
                }
                bydate
                clearbutton
                drawerheader
                filter
                header
                searchcard {
                  date
                  description {
                    richText
                  }
                  icon {
                    url
                  }
                  subdescription {
                    richText
                  }
                  title1 {
                    text
                  }
                }
                searchfind
                searchgroup {
                  checkbox
                }
                searchgroup1 {
                  checkbox
                }
                searchgroup2 {
                  checkbox
                }
                searchresult
                selectby
                showcount
                subtile {
                  text
                }
                subtitle_1
                subtitle_2 {
                  text
                }
                title {
                  text
                }
                selectbygroup {
                  option
                }
                selectcountgroup {
                  option
                }
              }
            }
          }
      }
  `);
    return sanitize(response.allPrismicSearch.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        header: response.header,
        accordionTitle: response.accordiontitle.text,
        byDate: response.bydate,
        clearButton: response.clearbutton,
        drawerHeader: response.drawerheader,
        filter: response.filter,
        searchFind: response.searchfind,
        searchResult: response.searchresult,
        selectBy: response.selectby,
        showCount: response.showcount,
        subTile: response.subtile.text,
        subTitle_1: response.subtitle_1,
        subTitle_2: response.subtitle_2.text,
        title: response.title.text,
        searchCard: response.searchcard.map(card => ({
            title: card.title1.text,
            description: card.description.richText,
            subDescription: card.subdescription.richText,
            date: card.date,
            icon: card.icon.url,
        })),
        searchGroup: response.searchgroup.map(checkBox => checkBox.checkbox),
        searchGroup_1: response.searchgroup1.map(checkBox => checkBox.checkbox),
        searchGroup_2: response.searchgroup2.map(checkBox => checkBox.checkbox),
        selectByGroup: response.selectbygroup.map(option => option.option),
        selectCountGroup: response.selectcountgroup.map(option => option.option)
    }
}
export default usePrismicSearch;